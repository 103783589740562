/*
 * @Author: liuzhenkun
 * @Date: 2018-03-15 14:14:39
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2018-07-10 17:18:49
 */

export default {
    name: 'Loan',

    title: 'Pusat Bantuan',

    data() {
        return {
            loanInfo: ''
        };
    },

    created() {
        const vm = this;

        vm.loanInfo = vm.$t('webview.helpCenter.loanInfo');
    }
};
